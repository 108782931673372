<template>
  <v-card color="white" class="pa-8">
    <v-card-text class="px-0">
      <div>
        <div class="bsat__title">{{ $t("title") }}</div>

        <!-- ******************** OVERVIEW ******************************* -->
        <section id="overview">
          <div class="bsat__paragraph">{{ $t("overview.paragraph") }}</div>
          <ReadMore>
            <div class="bsat__paragraph">{{ $t("overview.paragraph2") }}</div>
            <div class="bsat__paragraph">{{ $t("overview.paragraph3") }}</div>
          </ReadMore>
        </section>

        <!-- ************************ IMPLEMENTATION RECOMMENDATIONS ********************************* -->
        <section id="implementationRecommendations">
          <div class="bsat__subtitle mb-4">
            <strong>{{ $t("implementationRecommendations.title") }}</strong>
          </div>

          <ReadMore :intro="$t('implementationRecommendationReadMore')">
            <i18n
              path="implementationRecommendations.task.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.task.title")
                }}</strong>
              </template>
            </i18n>

            <i18n
              path="implementationRecommendations.taskLeader.text"
              tag="div"
              class="bsat__paragraph"
            >
              <template #title>
                <strong>{{
                  $t("implementationRecommendations.taskLeader.title")
                }}</strong>
              </template>
            </i18n>

            <div class="bsat__paragraph mb-4">
              <strong>{{
                $t("implementationRecommendations.proposedMethods.title")
              }}</strong>
            </div>

            <div>
              <ul>
                <li
                  class="bsat__paragraph mb-4"
                  v-for="(item, i) in proposedMethods"
                  :key="i"
                >
                  {{ item }}
                </li>
              </ul>
            </div>
          </ReadMore>
        </section>

        <!-- ****************** RECOMMENDED RESOURCES ************************** -->

        <section id="recommendedResources">
          <div class="bsat__subtitle mb-4">
            {{ $t("recommendedResources.title") }}
          </div>

          <!-- <div class="bsat__paragraph mt-n3 mb-6">
            {{ $t("recommendedResourcesIntro") }}
          </div> -->

          <ul class="ml-0 ml-sm-4 bsat__ul">
            <li>
              <i18n
                path="recommendedResources.paragraph.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #title>
                  <strong>{{
                    $t("recommendedResources.paragraph.title")
                  }}</strong>
                </template>
                <template #link>
                  <a href="/new?phase=3&step=2">{{
                    $t("recommendedResources.paragraph.link")
                  }}</a>
                </template>
              </i18n>
            </li>

            <li>
              <i18n
                path="recommendedResources.paragraph2.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #title>
                  <strong>{{
                    $t("recommendedResources.paragraph2.title")
                  }}</strong>
                </template>
                <template #link>
                  <a href="/new?phase=3&step=3">{{
                    $t("recommendedResources.paragraph2.link")
                  }}</a>
                </template>
              </i18n>
            </li>

            <li>
              <i18n
                path="recommendedResources.paragraph3.text"
                tag="div"
                class="bsat__paragraph"
              >
                <!-- <template #title>
                  <strong>{{
                    $t("recommendedResources.paragraph3.title")
                  }}</strong>
                </template> -->
              </i18n>
            </li>

            <!-- <li>
              <div class="bsat__paragraph">
                {{ $t("recommendedResources.paragraph3.pdf1") }}
              </div>
              <a :href="recommendedResourcesPDF1" target="_blank" download>
                <v-btn
                  depressed
                  width="150"
                  height="26"
                  class="bsat__btn"
                  dark
                  color="dark"
                  >{{ $t("download") }}</v-btn
                >
              </a>
            </li>

            <li>
              <div class="bsat__paragraph">
                {{ $t("recommendedResources.paragraph3.pdf2") }}
              </div>
              <a :href="recommendedResourcesPDF2" target="_blank" download>
                <v-btn
                  depressed
                  width="150"
                  height="26"
                  class="bsat__btn"
                  dark
                  color="dark"
                  >{{ $t("download") }}</v-btn
                >
              </a>
            </li> -->

            <li>
              <!--********************** REGIONAL POLICIES ************************* -->

              <i18n
                path="regionalPolicies.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link>
                  <strong>{{ $t("regionalPolicies.link") }}</strong>
                </template>
              </i18n>

              <v-btn
                outlined
                width="150"
                height="26"
                class="bsat__btn"
                color="dark"
                :to="regionalPoliciesLink"
                >{{ $t("visit") }}</v-btn
              >
              <!-- <a
                :href="require('@/assets/files/financial_tools.docx').default"
                target="_blank"
                download
              >
                <v-btn
                  class="bsat__btn"
                  color="dark"
                  dark
                  depressed
                  width="150"
                  height="26"
                  >{{ $t("download") }}</v-btn
                >
              </a> -->
            </li>

            <li>
              <!-- ******************************* TOOLS REPOSITORY **************************** -->
              <i18n
                path="toolsRepository.text"
                tag="div"
                class="bsat__paragraph"
              >
                <template #link>
                  <strong>{{ $t("toolsRepository.link") }}</strong>
                </template>
              </i18n>

              <v-btn
                outlined
                width="150"
                height="26"
                class="bsat__btn"
                color="dark"
                :to="toolsRepositoryLink"
                >{{ $t("visit") }}</v-btn
              >
              <!-- <a
                :href="require('@/assets/files/financial_tools.docx').default"
                target="_blank"
                download
              >
                <v-btn
                  class="bsat__btn"
                  color="dark"
                  dark
                  depressed
                  width="150"
                  height="26"
                  >{{ $t("download") }}</v-btn
                >
              </a> -->
            </li>

            <!-- ****************************** GUIDELINES *************************** -->
            <!-- <li>

              <div>
                <i18n path="guidelines.text" tag="div" class="bsat__paragraph">
                  <template #title>
                    <strong>{{ $t("guidelines.title") }}</strong>
                  </template>
                </i18n>
                <a :href="guidlinesFile" target="_blank" download="">
                  <v-btn
                    depressed
                    width="150"
                    height="26"
                    class="bsat__btn"
                    dark
                    color="dark"
                    >{{ $t("download") }}</v-btn
                  >
                </a>
              </div>
            </li> -->
            <li>
              <!-- ****************************** BEST PRACTISES *************************** -->

              <div>
                <i18n
                  path="bestPractises.text"
                  tag="div"
                  class="bsat__paragraph"
                >
                  <template #title>
                    <strong>{{ $t("bestPractises.title") }}</strong>
                  </template>
                </i18n>
                <router-link
                  to="/best-practice-examples-of-bio-based-solutions-with-replicability-potential"
                >
                  <v-btn
                    outlined
                    width="150"
                    height="26"
                    class="bsat__btn"
                    dark
                    color="dark"
                    >{{ $t("visit") }}</v-btn
                  >
                </router-link>
              </div>
            </li>
          </ul>
          <!-- <i18n
            path="regionalPolicies.materials.text"
            tag="div"
            class="bsat__paragraph"
          >
            <template #title>
              <strong>{{ $t("regionalPolicies.materials.title") }}</strong>
            </template>
          </i18n> -->

          <!-- <i18n
            path="toolsRepository.materials.text"
            tag="div"
            class="bsat__paragraph"
          >
            <template #title>
              <strong>{{ $t("toolsRepository.materials.title") }}</strong>
            </template>
          </i18n> -->
        </section>

        <i18n
          class="mt-8"
          tag="div"
          path="downloadAll.text"
          style="font-family: Montserrat"
        >
          <template #link>
            <a class="dark-text mt-8" :href="zip" target="_blank" download>
              {{ $t("downloadAll.link") }}
            </a>
          </template>
        </i18n>
      </div>
    </v-card-text>
  </v-card>
</template>

<i18n>
{
  "en": {
    "bestPractises": {
      "text": "{title} Consult a selection of bio-based solutions from the POWER4BIO catalogue, which thoroughly describe their potential for replicability in rural areas",
      "title": "Best practice examples: "
    },
    "guidelines": {
      "text": "{title} Use the guidelines for the design of new financial instruments from POWER4BIO, to generate if necessary a novel financial instrument for the support of defined priority areas and corresponding main goals. Consider the gap analysis of financial instruments as a pre-requisite step.",
      "title": "Guidelines to financial instruments design: "
    },
    "implementationRecommendations": {
      "proposedMethods": {
        "title": "Proposed methods",
        "unorderedList": [
          "Discussion/workshop with a selected group of experts (Advisory group) and Interministerial/Interdepartmental group. ",
          "Identification and selection of policy and financial instruments not yet used in the region or not well exploited. "
        ]
      },
      "task": {
        "text": "{title}: Assign supportive policy and financial resources and mechanisms (existent and new) that could make possible the attainment of priority areas and objectives",
        "title": "Task"
      },
      "taskLeader": {
        "text": "{title}: Strategy development working group",
        "title": "Task leader"
      },
      "title": "Implementation Recommendations"
    },
    "overview": {
      "paragraph": "The definition of the new supporting policies and financial mechanisms must start firstly by carrying a gap analysis. To this end, it is necessary to assess how the  identified policy and financial mechanisms match with chosen priority areas. Secondly, it is required to revise the available supporting resources in your region, such as existing but also potential missing financial instruments and policies, or new modes of application.",
      "paragraph2": "A discussion between experts involved in the strategy development working group will be required, not only with the experts of the advisory group, but also with the decision-level of the Interministerial/interdepartmental group. A consensus regarding which new financial instruments and new policy instrument to be included in the strategy should be the expected resulting from this step.",
      "paragraph3": "During this step it is recommendable to analyse if any of the policy or financial mechanisms actually hinders the development of any of the priority areas of the strategy."
    },
    "recommendedResources": {
      "paragraph": {
        "link": "Step 2, Phase 3",
        "text": "Determined priority areas for guidance ({link})"
      },
      "paragraph2": {
        "link": "Step 3, Phase 3",
        "text": "Results from {link}"
      },
      "paragraph3": {
        "pdf1": "Questionnaire for bioeconomy regional financing status.",
        "pdf2": "Questionnaire for classification of regional policy driven initiatives, including elements to consider in this process...",
        "text": "{title} These documents provided below were used for the same purpose during the POWER4BIO project and they were very useful to the regions as working documents during this step.",
        "title": "Questionnaires to monitor progress: "
      },
      "title": "Recommended resources: "
    },
    "regionalPolicies": {
      "link": "Repository of supporting documents and tools for regional policies in the field of bioeconomy: ",
      "text": "{link}  Use the overview of supporting policies throughout the value chain and good policy practices to identify policy mechanism and or good policy practices that could be applied in the new regional bioeconomy strategy. Specially consult resources 3 and 4."
    },
    "summary": {
      "title": "Summary of possible methods",
      "unorderedList": [
        "Discussion/workshop with a selected group of experts (Advisory group) and Interministerial/Interdepartmental group. ",
        "Identification and selection of policy and financial instruments not yet used in the region or not well exploited. "
      ]
    },
    "title": "Step 4: Develop supporting policy, financial mechanisms and resources to the defined priority areas",
    "toolsRepository": {
      "link": "Repository of supporting documents and tools for the implementation of EU financial instruments in the area of bioeconomy:",
      "text": "{link} Use the repository of financial instruments summarized in POWER4BIO as guideline to identify financial instruments not yet used, or innovative uses of them (Gap analysis)."
    },
    "downloadAll": {
			"link": "click here",
			"text": "*To download all the materials of Phase 3 and Phase 4 as a zip file please {link}"
		}
  }
}
</i18n>

<script>
import ReadMore from "@/components/ReadMore";
import { EventBus } from "@/utils/EventBus";
export default {
  name: "Phase3Step4",
  components: {
    ReadMore
  },
  data() {
    return {
      // TODO: 1.3.4_1
      recommendedResourcesPDF1: "",
      // TODO: 1.3.4_2
      recommendedResourcesPDF2: "",
      // TODO: 1.3.4_3
      guidlinesFile: "",
      bestPractisesFile: require("@/assets/files/1_3_2_2_Best_practice_examples_of_bio_based_solutions_with_replicability_potential.docx")
        .default,
      toolsRepositoryLink: "/repository-of-financing-instruments-and-tools",
      regionalPoliciesLink:
        "/repository-of-regional-policies-to-support-bio-based-models",
      zip: require("@/assets/files/Phase3_Phase4_common_resources.zip").default
    };
  },
  computed: {
    proposedMethods() {
      return Object.values(
        this.$t("implementationRecommendations.proposedMethods.unorderedList")
      );
    },
    resourcesUnorderedList() {
      return Object.values(this.$t("recomendedResources.unorderedList"));
    }
  },
  methods: {
    goToTab(tab) {
      EventBus.$emit("goToTab", tab);
    },
    goToStep(step) {
      EventBus.$emit("goToStep", step);
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/main";

.list {
  $font-family: $font-family;
  // font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: justify;

  &-link {
    color: $light_green !important;
  }
}
</style>
